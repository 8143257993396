import React from 'react';
import classes from './Journey.module.css';

const Journey = () => {
    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <span className={`fs-06 text-center`}>Journey</span>

            <div className={`row jc-start ai-start width-70 mt-4`}>


                <div className={`column jc-center ai-center ${classes.content}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                        <g filter="url(#filter0_b_310_996)">
                            <rect x="8.625" y="8" width="56" height="56" rx="28" fill="#6389FF"/>
                            <rect x="4.625" y="4" width="64" height="64" rx="32" stroke="#EAEAEA" strokeOpacity="0.2" strokeWidth="8"/>
                        </g>
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="white">1</text>
                        <defs>
                            <filter id="filter0_b_310_996" x="-8.375" y="-9" width="90" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.5"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_310_996"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_310_996" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="40" viewBox="0 0 7 40" fill="none">
                        <path d="M3.625 0V39.5" stroke="#6389FF" strokeOpacity="0.44" strokeWidth="6"/>
                    </svg>


                    <span style={{zIndex: "2"}}>
                         <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
                            <circle cx="16.125" cy="15.5" r="13" fill="#EEC139" stroke="#151519" strokeWidth="5"/>
                        </svg>
                    </span>

                    <div className={`${classes.card} px-2 flex jc-center ai-center position-absolute`}>
                        <span>Discover Investment <br/> Opportunities</span>
                    </div>
                </div>

                <div className={`position-relative flex jc-center ai-center`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="58" viewBox="0 0 84 58" fill="none">
                        <mask id="path-1-inside-1_280_19485" fill="white">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z"/>
                        </mask>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z" fill="#151519"/>
                        <path d="M1.03669 6.61466L2.98744 4.84125L1.03669 6.61466ZM21.387 29L19.4362 30.7734L20.2326 31.6494L21.4163 31.6362L21.387 29ZM21.3872 29L23.3379 30.7734L27.4078 26.2966L21.3579 26.3638L21.3872 29ZM1.03686 51.3853L-0.91389 49.6119H-0.913891L1.03686 51.3853ZM58.5471 56.2741L56.5963 54.5007L58.5471 56.2741ZM83.3417 29L85.2925 30.7734L89.3637 26.295L83.3118 26.3638L83.3417 29ZM83.3416 29L81.3908 30.7734L82.1874 31.6497L83.3715 31.6362L83.3416 29ZM58.5469 1.72591L60.4977 -0.047503L60.4977 -0.047506L58.5469 1.72591ZM2.98744 4.84125C2.21766 3.99449 2.81845 2.63636 3.96282 2.63636V-2.63636C-1.75903 -2.63636 -4.76299 4.15424 -0.914061 8.38807L2.98744 4.84125ZM23.3378 27.2266L2.98744 4.84125L-0.914061 8.38807L19.4362 30.7734L23.3378 27.2266ZM21.3579 26.3638L21.3577 26.3638L21.4163 31.6362L21.4165 31.6362L21.3579 26.3638ZM19.4364 27.2266L-0.91389 49.6119L2.98761 53.1588L23.3379 30.7734L19.4364 27.2266ZM-0.913891 49.6119C-4.76282 53.8458 -1.75885 60.6364 3.96299 60.6364V55.3636C2.81862 55.3636 2.21783 54.0055 2.98761 53.1588L-0.913891 49.6119ZM3.96299 60.6364H54.6456V55.3636H3.96299V60.6364ZM54.6456 60.6364C56.8739 60.6364 58.9989 59.6963 60.4978 58.0475L56.5963 54.5007C56.0967 55.0503 55.3884 55.3636 54.6456 55.3636V60.6364ZM60.4978 58.0475L85.2925 30.7734L81.391 27.2266L56.5963 54.5007L60.4978 58.0475ZM83.3118 26.3638L83.3116 26.3638L83.3715 31.6362L83.3717 31.6362L83.3118 26.3638ZM56.5962 3.49932L81.3908 30.7734L85.2923 27.2266L60.4977 -0.047503L56.5962 3.49932ZM54.6454 2.63636C55.3882 2.63636 56.0965 2.9497 56.5962 3.49932L60.4977 -0.047506C58.9987 -1.69634 56.8738 -2.63636 54.6454 -2.63636V2.63636ZM3.96282 2.63636H54.6454V-2.63636H3.96282V2.63636Z" fill="#27283B" mask="url(#path-1-inside-1_280_19485)"/>
                    </svg>

                    <div className={`position-absolute flex jc-center ai-center ${classes.line}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="12" viewBox="0 0 210 12" fill="none">
                            <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM198.667 6C198.667 8.94552 201.054 11.3333 204 11.3333C206.946 11.3333 209.333 8.94552 209.333 6C209.333 3.05448 206.946 0.666667 204 0.666667C201.054 0.666667 198.667 3.05448 198.667 6ZM6 7H10.125V5H6V7ZM18.375 7H26.625V5H18.375V7ZM34.875 7H43.125V5H34.875V7ZM51.375 7H59.625V5H51.375V7ZM67.875 7H76.125V5H67.875V7ZM84.375 7H92.625V5H84.375V7ZM100.875 7H109.125V5H100.875V7ZM117.375 7H125.625V5H117.375V7ZM133.875 7H142.125V5H133.875V7ZM150.375 7H158.625V5H150.375V7ZM166.875 7H175.125V5H166.875V7ZM183.375 7H191.625V5H183.375V7ZM199.875 7H204V5H199.875V7Z" fill="#6389FF" fillOpacity="0.44"/>
                        </svg>
                    </div>
                </div>


                <div className={`column jc-center ai-center ${classes.content}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                        <g filter="url(#filter0_b_310_996)">
                            <rect x="8.625" y="8" width="56" height="56" rx="28" fill="#6389FF"/>
                            <rect x="4.625" y="4" width="64" height="64" rx="32" stroke="#EAEAEA" strokeOpacity="0.2" strokeWidth="8"/>
                        </g>
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"fill="white">2</text>
                        <defs>
                            <filter id="filter0_b_310_996" x="-8.375" y="-9" width="90" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.5"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_310_996"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_310_996" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="40" viewBox="0 0 7 40" fill="none">
                        <path d="M3.625 0V39.5" stroke="#6389FF" strokeOpacity="0.44" strokeWidth="6"/>
                    </svg>


                    <span style={{zIndex: "2"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
                            <circle cx="16.375" cy="15.5" r="13" fill="#6389FF" stroke="#151519" strokeWidth="5"/>
                        </svg>
                    </span>

                    <div className={`${classes.card} px-2 flex jc-center ai-center position-absolute`}>
                        <span>Invest in Your <br/> Chosen Vault</span>
                    </div>

                </div>

                <div className={`position-relative flex jc-center ai-center`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="58" viewBox="0 0 84 58" fill="none">
                        <mask id="path-1-inside-1_280_19485" fill="white">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z"/>
                        </mask>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z" fill="#151519"/>
                        <path d="M1.03669 6.61466L2.98744 4.84125L1.03669 6.61466ZM21.387 29L19.4362 30.7734L20.2326 31.6494L21.4163 31.6362L21.387 29ZM21.3872 29L23.3379 30.7734L27.4078 26.2966L21.3579 26.3638L21.3872 29ZM1.03686 51.3853L-0.91389 49.6119H-0.913891L1.03686 51.3853ZM58.5471 56.2741L56.5963 54.5007L58.5471 56.2741ZM83.3417 29L85.2925 30.7734L89.3637 26.295L83.3118 26.3638L83.3417 29ZM83.3416 29L81.3908 30.7734L82.1874 31.6497L83.3715 31.6362L83.3416 29ZM58.5469 1.72591L60.4977 -0.047503L60.4977 -0.047506L58.5469 1.72591ZM2.98744 4.84125C2.21766 3.99449 2.81845 2.63636 3.96282 2.63636V-2.63636C-1.75903 -2.63636 -4.76299 4.15424 -0.914061 8.38807L2.98744 4.84125ZM23.3378 27.2266L2.98744 4.84125L-0.914061 8.38807L19.4362 30.7734L23.3378 27.2266ZM21.3579 26.3638L21.3577 26.3638L21.4163 31.6362L21.4165 31.6362L21.3579 26.3638ZM19.4364 27.2266L-0.91389 49.6119L2.98761 53.1588L23.3379 30.7734L19.4364 27.2266ZM-0.913891 49.6119C-4.76282 53.8458 -1.75885 60.6364 3.96299 60.6364V55.3636C2.81862 55.3636 2.21783 54.0055 2.98761 53.1588L-0.913891 49.6119ZM3.96299 60.6364H54.6456V55.3636H3.96299V60.6364ZM54.6456 60.6364C56.8739 60.6364 58.9989 59.6963 60.4978 58.0475L56.5963 54.5007C56.0967 55.0503 55.3884 55.3636 54.6456 55.3636V60.6364ZM60.4978 58.0475L85.2925 30.7734L81.391 27.2266L56.5963 54.5007L60.4978 58.0475ZM83.3118 26.3638L83.3116 26.3638L83.3715 31.6362L83.3717 31.6362L83.3118 26.3638ZM56.5962 3.49932L81.3908 30.7734L85.2923 27.2266L60.4977 -0.047503L56.5962 3.49932ZM54.6454 2.63636C55.3882 2.63636 56.0965 2.9497 56.5962 3.49932L60.4977 -0.047506C58.9987 -1.69634 56.8738 -2.63636 54.6454 -2.63636V2.63636ZM3.96282 2.63636H54.6454V-2.63636H3.96282V2.63636Z" fill="#27283B" mask="url(#path-1-inside-1_280_19485)"/>
                    </svg>

                    <div className={`position-absolute flex jc-center ai-center ${classes.line}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="12" viewBox="0 0 210 12" fill="none">
                            <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM198.667 6C198.667 8.94552 201.054 11.3333 204 11.3333C206.946 11.3333 209.333 8.94552 209.333 6C209.333 3.05448 206.946 0.666667 204 0.666667C201.054 0.666667 198.667 3.05448 198.667 6ZM6 7H10.125V5H6V7ZM18.375 7H26.625V5H18.375V7ZM34.875 7H43.125V5H34.875V7ZM51.375 7H59.625V5H51.375V7ZM67.875 7H76.125V5H67.875V7ZM84.375 7H92.625V5H84.375V7ZM100.875 7H109.125V5H100.875V7ZM117.375 7H125.625V5H117.375V7ZM133.875 7H142.125V5H133.875V7ZM150.375 7H158.625V5H150.375V7ZM166.875 7H175.125V5H166.875V7ZM183.375 7H191.625V5H183.375V7ZM199.875 7H204V5H199.875V7Z" fill="#6389FF" fillOpacity="0.44"/>
                        </svg>
                    </div>
                </div>

                <div className={`column jc-center ai-center ${classes.content}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                        <g filter="url(#filter0_b_310_996)">
                            <rect x="8.625" y="8" width="56" height="56" rx="28" fill="#6389FF"/>
                            <rect x="4.625" y="4" width="64" height="64" rx="32" stroke="#EAEAEA" strokeOpacity="0.2" strokeWidth="8"/>
                        </g>
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"fill="white">3</text>
                        <defs>
                            <filter id="filter0_b_310_996" x="-8.375" y="-9" width="90" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.5"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_310_996"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_310_996" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="40" viewBox="0 0 7 40" fill="none">
                        <path d="M3.625 0V39.5" stroke="#6389FF" strokeOpacity="0.44" strokeWidth="6"/>
                    </svg>

                    <span style={{zIndex: "2"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
                            <circle cx="16.375" cy="15.5" r="13" fill="#6389FF" stroke="#151519" strokeWidth="5"/>
                        </svg>
                    </span>

                    <div className={`${classes.card} px-2 flex jc-center ai-center position-absolute`}>
                        <span>Tracking <br/> Investments</span>
                    </div>
                </div>


                <div className={`position-relative flex jc-center ai-center`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="58" viewBox="0 0 84 58" fill="none">
                        <mask id="path-1-inside-1_280_19485" fill="white">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z"/>
                        </mask>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.96282 0C0.52971 0 -1.27267 4.07436 1.03669 6.61466L21.387 29L21.3872 29L1.03686 51.3853C-1.2725 53.9256 0.529881 58 3.96299 58H54.6456C56.1312 58 57.5478 57.3733 58.5471 56.2741L83.3417 29L83.3416 29L58.5469 1.72591C57.5476 0.626679 56.131 0 54.6454 0H3.96282Z" fill="#151519"/>
                        <path d="M1.03669 6.61466L2.98744 4.84125L1.03669 6.61466ZM21.387 29L19.4362 30.7734L20.2326 31.6494L21.4163 31.6362L21.387 29ZM21.3872 29L23.3379 30.7734L27.4078 26.2966L21.3579 26.3638L21.3872 29ZM1.03686 51.3853L-0.91389 49.6119H-0.913891L1.03686 51.3853ZM58.5471 56.2741L56.5963 54.5007L58.5471 56.2741ZM83.3417 29L85.2925 30.7734L89.3637 26.295L83.3118 26.3638L83.3417 29ZM83.3416 29L81.3908 30.7734L82.1874 31.6497L83.3715 31.6362L83.3416 29ZM58.5469 1.72591L60.4977 -0.047503L60.4977 -0.047506L58.5469 1.72591ZM2.98744 4.84125C2.21766 3.99449 2.81845 2.63636 3.96282 2.63636V-2.63636C-1.75903 -2.63636 -4.76299 4.15424 -0.914061 8.38807L2.98744 4.84125ZM23.3378 27.2266L2.98744 4.84125L-0.914061 8.38807L19.4362 30.7734L23.3378 27.2266ZM21.3579 26.3638L21.3577 26.3638L21.4163 31.6362L21.4165 31.6362L21.3579 26.3638ZM19.4364 27.2266L-0.91389 49.6119L2.98761 53.1588L23.3379 30.7734L19.4364 27.2266ZM-0.913891 49.6119C-4.76282 53.8458 -1.75885 60.6364 3.96299 60.6364V55.3636C2.81862 55.3636 2.21783 54.0055 2.98761 53.1588L-0.913891 49.6119ZM3.96299 60.6364H54.6456V55.3636H3.96299V60.6364ZM54.6456 60.6364C56.8739 60.6364 58.9989 59.6963 60.4978 58.0475L56.5963 54.5007C56.0967 55.0503 55.3884 55.3636 54.6456 55.3636V60.6364ZM60.4978 58.0475L85.2925 30.7734L81.391 27.2266L56.5963 54.5007L60.4978 58.0475ZM83.3118 26.3638L83.3116 26.3638L83.3715 31.6362L83.3717 31.6362L83.3118 26.3638ZM56.5962 3.49932L81.3908 30.7734L85.2923 27.2266L60.4977 -0.047503L56.5962 3.49932ZM54.6454 2.63636C55.3882 2.63636 56.0965 2.9497 56.5962 3.49932L60.4977 -0.047506C58.9987 -1.69634 56.8738 -2.63636 54.6454 -2.63636V2.63636ZM3.96282 2.63636H54.6454V-2.63636H3.96282V2.63636Z" fill="#27283B" mask="url(#path-1-inside-1_280_19485)"/>
                    </svg>

                    <div className={`position-absolute flex jc-center ai-center ${classes.line}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="12" viewBox="0 0 210 12" fill="none">
                            <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM198.667 6C198.667 8.94552 201.054 11.3333 204 11.3333C206.946 11.3333 209.333 8.94552 209.333 6C209.333 3.05448 206.946 0.666667 204 0.666667C201.054 0.666667 198.667 3.05448 198.667 6ZM6 7H10.125V5H6V7ZM18.375 7H26.625V5H18.375V7ZM34.875 7H43.125V5H34.875V7ZM51.375 7H59.625V5H51.375V7ZM67.875 7H76.125V5H67.875V7ZM84.375 7H92.625V5H84.375V7ZM100.875 7H109.125V5H100.875V7ZM117.375 7H125.625V5H117.375V7ZM133.875 7H142.125V5H133.875V7ZM150.375 7H158.625V5H150.375V7ZM166.875 7H175.125V5H166.875V7ZM183.375 7H191.625V5H183.375V7ZM199.875 7H204V5H199.875V7Z" fill="#6389FF" fillOpacity="0.44"/>
                        </svg>
                    </div>
                </div>

                <div className={`column jc-center ai-center ${classes.content}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                        <g filter="url(#filter0_b_310_996)">
                            <rect x="8.625" y="8" width="56" height="56" rx="28" fill="#6389FF"/>
                            <rect x="4.625" y="4" width="64" height="64" rx="32" stroke="#EAEAEA" strokeOpacity="0.2" strokeWidth="8"/>
                        </g>
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"fill="white">4</text>
                        <defs>
                            <filter id="filter0_b_310_996" x="-8.375" y="-9" width="90" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.5"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_310_996"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_310_996" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="40" viewBox="0 0 7 40" fill="none">
                        <path d="M3.625 0V39.5" stroke="#6389FF" strokeOpacity="0.44" strokeWidth="6"/>
                    </svg>
                    <span style={{zIndex: "2"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
                            <circle cx="16.375" cy="15.5" r="13" fill="#6389FF" stroke="#151519" strokeWidth="5"/>
                        </svg>
                    </span>

                    <div className={`${classes.card} px-2 flex jc-center ai-center position-absolute`}>
                        <span>Taking Profit</span>
                    </div>
                </div>




            </div>









        </div>
    );
};

export default Journey;
