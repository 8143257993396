import React from 'react';
import classes from './WhyUs.module.css';
import {images} from "../../../../../assets/images/images";
import Button from "../../../../../components/Button/Button";

const WhyUs = () => {
    return (
        <div className={`${classes.container} width-100 column jc-start ai-center`}
             style={{backgroundImage: `url("${images.shaddow}")`}}
        >

            <span className={`fs-06 text-center`}>why us?</span>

            <div className={`width-70 row jc-between ai-start wrap mt-5`}>
                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.expertize} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 text-yellow mt-1 mb-2`}>Expertise in DeFi</p>
                    <span>With years of experience in the DeFi space, our team of experts brings unparalleled knowledge to the table. We understand the complexities and nuances of decentralized finance, allowing us to curate investment strategies that optimize returns while mitigating risks.</span>
                </div>

                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.secutity} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 mt-1 mb-2`}>Cutting-Edge Security</p>
                    <span>Security is at the heart of our platform. We employ state-of-the-art security measures, including regular audits and robust encryption, to safeguard your investments. Rest easy knowing that your assets are protected with us.
                    </span>
                </div>


                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.user} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 mt-1 mb-2`}>User-Focused Design</p>
                    <span>We prioritize user experience. Our intuitive platform design makes DeFi accessible to both beginners and experienced investors. You'll find managing your investments a breeze, empowering you to achieve your financial goals.</span>
                </div>


                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.diverse} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 mt-1 mb-2`}>Diverse Investment Options</p>
                    <span>We offer a wide range of curated vaults and investment opportunities, catering to various risk appetites and profit goals. Whether you're a conservative or adventurous investor, you'll find options tailored to your preferences.
                    </span>
                </div>


                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.innovation} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 mt-1 mb-2`}>Continuous Innovation</p>
                    <span>Innovation is in our DNA. We're constantly exploring new technologies and strategies to improve your investment experience. Stay at the forefront of DeFi with our platform</span>
                </div>

                <div className={`${classes.card} width-32 my-2 column jc-start ai-start px-2 py-3`}>
                    <img src={images.provenTrackRecord} alt="" className={`${classes.icon}`}/>
                    <p className={`fs-04 mt-1 mb-2`}>Proven Track Record</p>
                    <span>Trust is earned through results. Our platform has a proven track record of delivering consistent returns and secure investments. Join the ranks of satisfied investors who've achieved their financial goals with us</span>
                </div>

                <div className={`width-100 flex jc-center ai-center mt-2`}>
                    <Button
                        type="submit"
                        buttonClass={`${classes.thisButton} px-3`}
                        buttonTitle="CTA"
                    />
                </div>




            </div>







        </div>
    );
};

export default WhyUs;
