import React, {useState} from 'react';
import classes from './FAQ.module.css'
import Card from "./Module/Card/Card";
import Button from "../../../../../components/Button/Button";

const FAQ = () => {

    const [isOpen, setIsOpen] = useState(0);







    const faqData = [
        {title: "How do I get started on the platform?", content: "To begin, simply register an account, complete your profile, and explore our curated vaults. Allocate your funds and start investing", id:"1"},
        {title: "Is my investment safe on your platform?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", id:"2"},
        {title: "Can I change my investment strategy or risk profile?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", id:"3"},
        {title: "How do I track my investment performance?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", id:"4"},
        {title: "What fees are associated with using your platform?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", id:"5"},
        {title: "Is there a minimum investment requirement?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", id:"6"},
    ]

    return (

        <>


            <div className={`${classes.container} width-100 column jc-start ai-center my-15`}>
                <span className={`fs-06 font-weight-bold mb-4`}>FAQ</span>

                <div className={`width-70 column jc-canter ai-center`}>
                    {faqData.map((faq , index) => <Card data={faq} index={index} key={index} isOpen={isOpen} setIsOpen={setIsOpen}/>)}

                    <div className={`${classes.card} my-1 width-70 px-2 py-3 row jc-between ai-center`}>

                        <span className={`fs-02`}>Do you need more information?</span>

                        <Button
                            type="submit"
                            buttonClass={`${classes.thisButton} fs-01 px-2`}
                            buttonTitle="secondary CTA"
                        />
                    </div>
                </div>





            </div>


        </>

    );
};

export default FAQ;
