import React from 'react';
import classes from './Info.module.css';
import {images} from "../../../../../assets/images/images";
import Button from "../../../../../components/Button/Button";
import Header from "../../../../Layout/Header/Header";

const Info = () => {
    return (
        <div className={`${classes.container} width-100 column jc-start ai-center`}
            style={{backgroundImage: `url("${images.banner}")`}}
        >

            <Header/>

            <div className={`${classes.content} width-70 mb-3`}>

                <div className={`column jc-start fs-25`}>
                     <span className={`font-weight-bold mb-1`}>Investing</span>
                    <div className={`row mt-1`}>
                        <span>in the Future</span>
                        <span className={`font-weight-bold`}>, Today</span>
                    </div>
                </div>

                <div className={`width-33 mt-4 mb-2 fs-02`}>
                    Discover the limitless possibilities of  <span className={`text-yellow`}>DeFi</span>  with our user-friendly platform. Start building your financial future today by harnessing the power of decentralized finance effortlessly.
                </div>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} px-2`}
                    buttonTitle="Launch App"
                />

            </div>

        </div>
    );
};

export default Info;
