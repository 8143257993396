import React from 'react';
import './Styles/Styles.css';
import {Route, Routes} from "react-router-dom";
import * as RoutesName from "./Routes/routes";
import Layout from "./Layout/Layout";
import Home from "./Pages/Home/Home";
import {isMobile} from "react-device-detect";
import {images} from "../assets/images/images";

const Main = () => {

    /*if(isMobile) {
        return <div className={` column jc-center ai-center width-100 mt-20`}>

            <img src={images.logoexCitex} style={{width:"40vw"}} alt=""/>
            <span className={`mt-5`}><span className={`font-weight-bold text-yellow fs-04`}>Defund</span> Not optimized for mobile view.</span>

        </div>
    }*/

    return (
        <Routes>
            <Route element={<Layout/>}>

                <Route path={RoutesName.Home} element={<Home/>}/>

            </Route>
        </Routes>
    );
};

export default Main;
