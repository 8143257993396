import React from 'react';
import classes from './Layout.module.css'
import {Outlet} from "react-router-dom";
import ScrollBar from "../../components/ScrollBar";
import Header from "./Header/Header";

const Layout = () => {
    return (
        <div className={`${classes.container} width-100 flex`}>

            <ScrollBar>
                <div className={`${classes.content} width-100 column jc-start ai-center`}>
                    {/*<Header/>*/}
                    <Outlet/>
                </div>
            </ScrollBar>

        </div>
    );
};

export default Layout;
