import React from 'react';
import classes from "./Footer.module.css";
import Button from "../../../../../components/Button/Button";


const Footer = () => {
    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>

            <span className={`font-weight-bold fs-09`}>Defund</span>
            <span className={`my-3`}>Lorem ipsum dolor , cta pharse</span>

            <Button
                type="submit"
                buttonClass={`${classes.thisButton} px-3`}
                buttonTitle="CTA"
            />

            <div className={`my-3 flex jc-center ai-center`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="83%" height="2" viewBox="0 0 1432 2" fill="none" dominantBaseline="middle" textAnchor="middle">
                    <g opacity="0.2">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33594 0.666667H1430.67H1.33594Z" fill="white"/>
                        <path d="M1.33594 0.666667H1430.67" stroke="#EEC139" stroke-width="1.33333" stroke-linecap="square"/>
                    </g>
                </svg>
            </div>

            <div className={`row jc-center ai-center`}>

                <span className={`mx-05`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 60 61" fill="none">
                        <path opacity="0.25" d="M58.5 30.667C58.5 46.4071 45.7401 59.167 30 59.167C14.2599 59.167 1.5 46.4071 1.5 30.667C1.5 14.9269 14.2599 2.16699 30 2.16699C45.7401 2.16699 58.5 14.9269 58.5 30.667Z" stroke="#6389FF" stroke-width="3"/>
                        <path d="M23.5859 26.5005C24.8286 26.5005 25.8359 25.4931 25.8359 24.2505C25.8359 23.0078 24.8286 22.0005 23.5859 22.0005C22.3433 22.0005 21.3359 23.0078 21.3359 24.2505C21.3359 25.4931 22.3433 26.5005 23.5859 26.5005Z" fill="#6389FF"/>
                        <path d="M25.4609 28.0005H21.7109C21.5039 28.0005 21.3359 28.1685 21.3359 28.3755V39.6255C21.3359 39.8325 21.5039 40.0005 21.7109 40.0005H25.4609C25.6679 40.0005 25.8359 39.8325 25.8359 39.6255V28.3755C25.8359 28.1685 25.6679 28.0005 25.4609 28.0005Z" fill="#6389FF"/>
                        <path d="M36.6337 27.4838C35.0309 26.9348 33.0262 27.417 31.8239 28.2818C31.7827 28.1205 31.6357 28.0005 31.4609 28.0005H27.7109C27.5039 28.0005 27.3359 28.1685 27.3359 28.3755V39.6255C27.3359 39.8325 27.5039 40.0005 27.7109 40.0005H31.4609C31.6679 40.0005 31.8359 39.8325 31.8359 39.6255V31.5405C32.4419 31.0185 33.2227 30.852 33.8617 31.1235C34.4812 31.3853 34.8359 32.0243 34.8359 32.8755V39.6255C34.8359 39.8325 35.0039 40.0005 35.2109 40.0005H38.9609C39.1679 40.0005 39.3359 39.8325 39.3359 39.6255V32.1203C39.2932 29.0385 37.8434 27.8978 36.6337 27.4838Z" fill="#6389FF"/>
                    </svg>
                </span>
                <span className={`mx-05`}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 60 61" fill="none">
                    <path opacity="0.25" d="M58.6667 30.667C58.6667 46.4992 45.8322 59.3337 30 59.3337C14.1678 59.3337 1.33333 46.4992 1.33333 30.667C1.33333 14.8348 14.1678 2.00033 30 2.00033C45.8322 2.00033 58.6667 14.8348 58.6667 30.667Z" stroke="#6389FF" stroke-width="2.66667"/>
                    <path d="M27.5987 39.8577L27.5714 31.4618H24V27.8635H27.5714V25.4647C27.5714 22.2272 29.5613 20.667 32.4278 20.667C33.8009 20.667 34.981 20.77 35.3249 20.816V24.1994L33.3369 24.2003C31.7779 24.2003 31.476 24.9466 31.476 26.0419V27.8635H35.9048L34.7143 31.4618H31.476V39.8577H27.5987Z" fill="#6389FF"/>
                </svg>
                </span>
                <span className={`mx-05`}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 60 61" fill="none">
                    <path opacity="0.25" d="M58.6667 30.667C58.6667 46.4992 45.8322 59.3337 30 59.3337C14.1678 59.3337 1.33333 46.4992 1.33333 30.667C1.33333 14.8348 14.1678 2.00033 30 2.00033C45.8322 2.00033 58.6667 14.8348 58.6667 30.667Z" stroke="#6389FF" stroke-width="2.66667"/>
                    <path d="M34.4931 27.2568C35.0675 27.2568 35.5331 26.7911 35.5331 26.2168C35.5331 25.6424 35.0675 25.1768 34.4931 25.1768C33.9187 25.1768 33.4531 25.6424 33.4531 26.2168C33.4531 26.7911 33.9187 27.2568 34.4931 27.2568Z" fill="#6389FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6719 30.6673C25.6719 33.0572 27.6154 35.0007 30.0052 35.0007C32.395 35.0007 34.3385 33.0572 34.3385 30.6673C34.3385 28.2775 32.395 26.334 30.0052 26.334C27.6154 26.334 25.6719 28.2775 25.6719 30.6673ZM27.8398 30.6673C27.8398 29.4724 28.8116 28.5006 30.0065 28.5006C31.2014 28.5006 32.1732 29.4724 32.1732 30.6673C32.1732 31.8622 31.2014 32.8339 30.0065 32.8339C28.8116 32.8339 27.8398 31.8622 27.8398 30.6673Z" fill="#6389FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6693 39.3338H34.3359C36.5633 39.3338 38.6693 37.2278 38.6693 35.0005V26.3338C38.6693 24.1065 36.5633 22.0005 34.3359 22.0005H25.6693C23.4419 22.0005 21.3359 24.1065 21.3359 26.3338V35.0005C21.3359 37.2278 23.4419 39.3338 25.6693 39.3338ZM23.5 26.3337C23.5 25.3207 24.6537 24.167 25.6667 24.167H34.3333C35.3463 24.167 36.5 25.3207 36.5 26.3337V35.0003C36.5 36.0132 35.3463 37.167 34.3333 37.167H25.6667C24.6342 37.167 23.5 36.0327 23.5 35.0003V26.3337Z" fill="#6389FF"/>
                </svg>
                </span>
                <span className={`mx-05`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 60 61" fill="none">
                    <path opacity="0.25" d="M58.6667 30.667C58.6667 46.4992 45.8322 59.3337 30 59.3337C14.1678 59.3337 1.33333 46.4992 1.33333 30.667C1.33333 14.8348 14.1678 2.00033 30 2.00033C45.8322 2.00033 58.6667 14.8348 58.6667 30.667Z" stroke="#6389FF" stroke-width="2.66667"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0212 23.7909C39.9347 24.0327 40.6542 24.7413 40.8994 25.6394C41.3545 27.2786 41.3332 30.6961 41.3332 30.6961C41.3332 30.6961 41.33 34.0948 40.8887 35.733C40.6403 36.63 39.9176 37.3376 39.0052 37.5783C37.336 38.0075 30.6581 38.0002 30.6581 38.0002C30.6581 38.0002 23.9982 37.9928 22.312 37.5427C21.3974 37.3009 20.6779 36.5923 20.4328 35.6953C19.9957 34.0729 20 30.6564 20 30.6564C20 30.6564 20.0043 27.2566 20.4445 25.6174C20.6918 24.7204 21.4315 23.9961 22.3269 23.7564C23.9972 23.3262 30.6741 23.3335 30.6741 23.3335C30.6741 23.3335 37.352 23.3408 39.0212 23.7909ZM28.5444 27.5255L28.5391 33.8058L34.0946 30.6709L28.5444 27.5255Z" fill="#6389FF"/>
                </svg>
                </span>






            </div>

        </div>
    );
};

export default Footer;
