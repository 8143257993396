import React from 'react';
import Info from "./Module/Info/Info";
import WhyUs from "./Module/WhyUs/WhyUs";
import Journey from "./Module/Journey/Journey";
import Features from "./Module/Features/Features";
import Image from "./Module/Image/Image";
import Vaults from "./Module/Vaults/Vaults";
import FAQ from "./Module/FAQ/FAQ";
import Footer from "./Module/Footer/Footer";
import Intro from "./Module/Intro/Intro";

const Home = () => {
    return (
        <>
            <Info/>
            <Intro/>
            <WhyUs/>
            <Journey/>
            <Features/>
            <Image/>
            <Vaults/>
            <FAQ/>
            <Footer/>

        </>
    );
};

export default Home;
