

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{fontSize:"10rem"}}>Defund</h1>
        <p style={{fontSize:"3rem"}}>Citex</p>
      </header>
    </div>
  );
}

export default App;
