import React from 'react';
import classes from './Vaults.module.css'
import {images} from "../../../../../assets/images/images";
import VaultCard from "./Module/VaultCard/VaultCard";
import Button from "../../../../../components/Button/Button";

const Vaults = () => {
    return (
        <div className={`${classes.container} width-100 column jc-start ai-center`}>
            <span className={`fs-06 font-weight-bold text-start width-70 mb-4`}>Vaults</span>

            {/*<div className={`row wrap width-70 jc-between ai-center`}>
                <VaultCard/>
                <VaultCard/>
                <VaultCard/>
                <VaultCard/>
            </div>*/}

            <div className={`row jc-between ai-center width-70 mb-1`}>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.vaultCard} alt="" className={`width-100`}/>
                </div>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.vaultCard} alt="" className={`width-100`}/>
                </div>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.vaultCard} alt="" className={`width-100`}/>
                </div>
            </div>
            <div className={`row jc-between ai-center width-70 mt-1`}>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.bundleVaultCard} alt="" className={`width-100`}/>
                </div>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.bundleVaultCard} alt="" className={`width-100`}/>
                </div>
                <div className={`width-32 flex jc-center ai-center`}>
                    <img src={images.bundleVaultCard} alt="" className={`width-100`}/>
                </div>
            </div>

            <div className={`width-100 flex jc-center ai-center mt-3`}>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} px-3`}
                    buttonTitle="CTA"
                />
            </div>


        </div>
    );
};

export default Vaults;
