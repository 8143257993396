import React from 'react';
import classes from './Features.module.css'
import {images} from "../../../../../assets/images/images";
import Button from "../../../../../components/Button/Button";

const Features = () => {
    return (
        <div className={`${classes.container} width-100 column jc-start ai-center position-relative`}
             //style={{backgroundImage: `url("${images.features}")`}}
        >

            <span className={`fs-06 text-center mt-20`} style={{zIndex:"2"}}>Features</span>

            <img src={images.features} alt="" className={`${classes.img} position-absolute`}/>

            <div className={`row jc-between width-70`} style={{zIndex:"2"}}>

                <div className={`column jc-between width-30`}>
                    <div className={`column my-4`}>
                        <img src={images.safe} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>Investment Vaults</p>
                        <span>Access a variety of curated vaults with different strategies and risk profiles, designed to meet your specific investment goals</span>
                    </div>
                    <div className={`column my-4`}>
                        <img src={images.userFriendly} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>User-Friendly Dashboard</p>
                        <span>Navigate and manage your investments effortlessly with our intuitive and user-friendly dashboard. Track your portfolio's performance in real-time</span>
                    </div>
                    <div className={`column my-4`}>
                        <img src={images.warning} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>Risk Management Tools</p>
                        <span>Utilize our risk assessment and management tools to make informed investment decisions. Tailor your portfolio to match your risk tolerance</span>
                    </div>
                </div>

                <div className={`column jc-between text-end width-30`}>
                    <div className={`column ai-end my-4`}>
                        <img src={images.securityMeasure} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>Security Measures</p>
                        <span>Rest assured knowing your assets are safeguarded by state-of-the-art security measures, including regular audits and encryption</span>
                    </div>
                    <div className={`column ai-end my-4`}>
                        <img src={images.analysis} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>Performance Analytics</p>
                        <span>Gain valuable insights into your investments with comprehensive performance analytics. Make data-driven decisions for optimized returns.</span>
                    </div>
                    <div className={`column ai-end my-4`}>
                        <img src={images.flexibility} alt="" className={`${classes.icon}`}/>
                        <p className={`text-yellow fs-04 mt-1 mb-2`}>Investment Flexibility</p>
                        <span>Enjoy the flexibility to adjust your investment allocations, rebalance your portfolio, and take profits when you choose.</span>
                    </div>
                </div>
            </div>



            <div className={`width-100 flex jc-center ai-center`} style={{zIndex:"2"}}>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} px-3`}
                    buttonTitle="CTA"
                />
            </div>

        </div>
    );
};

export default Features;
