import React from 'react';
import classes from './Header.module.css'
import Button from "../../../components/Button/Button";

const Header = () => {
    return (
        <div className={`${classes.header} width-70 px-3 py-2 row jc-between ai-center mt-6 mb-9`}>
            <span className={`fs-05`}>Defund</span>
            <Button
                type="submit"
                buttonClass={`${classes.thisButton} px-2`}
                buttonTitle="Launch App"
            />
        </div>
    );
};

export default Header;
