import React from 'react';
import classes from './Image.module.css';
import {images} from "../../../../../assets/images/images";

const Image = () => {
    return (
        <div className={`${classes.container} width-100 column jc-center ai-center my-10`}
             style={{backgroundImage: `url("${images.shadow3}")`}}
        >
            <span className={`fs-06 text-center mb-3`}><span className={`font-weight-bold`}>Dashboard</span> photo</span>
            <img src={images.mockupDashboard} alt="" className={`${classes.img}`}/>
            {/*<div className={`${classes.content} width-70 flex jc-center ai-center mt-3`}>
                 <div className={`${classes.mainImg} width-86 position-relative`}>

                     <div className={`${classes.leftSideImg} position-absolute`}>

                     </div>
                     <div className={`${classes.rightSideImg} position-absolute`}>

                     </div>

                 </div>
            </div>*/}
        </div>
    );
};

export default Image;
