import banner from "./src/banner.png"
import shaddow from "./src/shaddow.png"
import diverse from "./src/diverse.svg"
import expertize from "./src/expertize.svg"
import innovation from "./src/innovation.svg"
import provenTrackRecord from "./src/proven track record.svg"
import secutity from "./src/secutity.svg"
import user from "./src/user.svg"
import features from "./src/feet.png"
import analysis from "./src/analysis.svg"
import safe from "./src/safe.svg"
import securityMeasure from "./src/security measure.svg"
import userFriendly from "./src/user friendly.svg"
import flexibility from "./src/flexibility.svg"
import warning from "./src/warning.svg"
import shadow3 from "./src/shadow3.svg"
import vaultsImg from "./src/vaultsImg.svg"
import logoexCitex from "./src/logoexcitex.png"
import mockupDashboard from "./src/mockupDashboard.png"
import vaultCard from "./src/vault card 1.png"
import bundleVaultCard from "./src/bundle vault card 1.png"
import introImage from "./src/introimage.jpg"

export const images = {

    banner:banner,
    shaddow:shaddow,
    diverse:diverse,
    expertize:expertize,
    innovation:innovation,
    provenTrackRecord:provenTrackRecord,
    secutity:secutity,
    user:user,
    features:features,
    analysis:analysis,
    safe:safe,
    securityMeasure:securityMeasure,
    userFriendly:userFriendly,
    flexibility:flexibility,
    warning:warning,
    shadow3:shadow3,
    vaultsImg:vaultsImg,
    logoexCitex:logoexCitex,
    mockupDashboard:mockupDashboard,
    vaultCard:vaultCard,
    bundleVaultCard:bundleVaultCard,
    introImage:introImage,

};
