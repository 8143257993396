import React from 'react';
import classes from './Intro.module.css'
import {images} from "../../../../../assets/images/images";

const Intro = () => {
    return (
        <div className={`${classes.container} width-70 row jc-between ai-center`}>

            <div className={`flex jc-center ai-center width-48`}>
                <img src={images.introImage} alt="" className={`${classes.img}`}/>
            </div>
            <div className={`flex jc-center ai-center width-45`}>

                <span className={`fs-01`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus </span>
            </div>




        </div>
    );
};

export default Intro;
